import { render, staticRenderFns } from "./CuentasXPagar.vue?vue&type=template&id=80788a5c&scoped=true&"
import script from "./CuentasXPagar.vue?vue&type=script&lang=js&"
export * from "./CuentasXPagar.vue?vue&type=script&lang=js&"
import style0 from "./CuentasXPagar.vue?vue&type=style&index=0&id=80788a5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80788a5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCheckbox,VChip,VIcon,VList,VListItem,VListItemTitle,VMenu,VSimpleTable})
