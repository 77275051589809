<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.gestion')} / ${$t('menu.ctasXP')}` }}
        </v-card-title>
        <v-card-text><CtasList /></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CtasList from './tablas/CtasList.vue'

export default {
  components: {
    CtasList,
  },
  data() {
    return {}
  },
}
</script>
